import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml, Layout } from 'layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema/PageSchema';
import Seo from 'common/Seo/Seo';

import { PromoPageTypes } from './models';

import './Promo.scss';

const Promo: FC<{ data: PromoPageTypes }> = ({
  data: {
    promo: {
      bannerMain: { childrenImageSharp, altText },
      backgroundMap: { url: urlMap },
      backgroundColumnsDesktop: { url: urlDesk },
      link: { name, url },
      columns,
      map,
      youtubeURL,
      urls,
      seo,
      googleTagManagerId,
      eventConversion,
    },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent>
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`} />
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${googleTagManagerId}'); `}
        </script>
        <script>
          {`
            const element = document.getElementById("onClickGTM");
            function gtag_report_conversion(url) {
              var callback = function () { 
                if (typeof(url) != 'undefined') { 
                  window.location = url; 
                } 
              }; 
              gtag('event', 'conversion', { 'send_to': '${eventConversion}', 'event_callback': callback });
              return false; 
            }
            element.addEventListener("click", function(){
              gtag_report_conversion(document.getElementById("onClickGTM").href);
            });
          `}
        </script>
      </Helmet>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="banner-main position-relative">
        {childrenImageSharp && childrenImageSharp.length > 0 ? (
          <Image imageData={childrenImageSharp[0]} alt={altText} className="banner-main__image" />
        ) : null}
        <a
          href={url}
          id="onClickGTM"
          target="_blank"
          rel="noopener noreferrer"
          className="banner-main__btn btn btn-danger text-white"
        >
          {name}
        </a>
      </div>
      <div className="columns container-fluid" style={{ backgroundImage: `url(${urlDesk})` }}>
        <div className="columns__row row justify-content-center">
          {columns.map(({ text, image }) => (
            <div className="columns__col col-8 col-md-6 mb-3" key={image.altText}>
              <Image imageData={image} alt={image.altText} className="columns__image mb-3" />
              <DangerouslySetInnerHtml html={text} className="columns__content" />
            </div>
          ))}
        </div>

        {youtubeURL && (
          <div className="frame__iframe--video">
            <iframe
              src={youtubeURL}
              title="YouTube video player"
              frameBorder="0"
              allow="autoplay"
              className="iframe"
            />
          </div>
        )}
      </div>
      <div className="map" style={{ backgroundImage: `url(${urlMap})` }}>
        <div className="frame__iframe">
          <iframe
            src={map}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title={seoTitle}
            className="iframe"
          />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    promo {
      seo {
        ...SEOStructureFragment
      }
      urls {
        lang
        href
      }
      bannerMain {
        altText
        childrenImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1920)
        }
      }
      backgroundColumnsDesktop {
        url
      }
      backgroundMap {
        url
      }
      link {
        name
        url
      }
      map
      youtubeURL
      googleTagManagerId
      eventConversion
      columns {
        text
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
    }
  }
`;

export default Promo;
